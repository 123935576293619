"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.googleAuthConfigParameters = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("../types");
var oauthParamDocumentation = ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Use", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://github.com/jitsucom/oauthcli" }, { children: "Jitsu OAuth CLI Util" })), " ", "to obtain oauth credentials"] }));
/**
 * If props is ['a', 'b', 'c'] (or 'a.b.c'), result is the equivalent to obj?.a?.b?.c
 */
function resolve(obj, props) {
    return (typeof props === "string" ? props.split(".") : props).reduce(function (current, prop) { return (current ? current[prop] : undefined); }, obj);
}
function removeNulls(arr) {
    return arr.filter(function (el) { return !!el; });
}
var googleAuthConfigParameters = function (_a) {
    var _b = _a.clientId, clientId = _b === void 0 ? "config.auth.client_id" : _b, _c = _a.clientSecret, clientSecret = _c === void 0 ? "config.auth.client_secret" : _c, _d = _a.refreshToken, refreshToken = _d === void 0 ? "config.auth.refresh_token" : _d, _e = _a.type, type = _e === void 0 ? "config.auth.type" : _e, _f = _a.disableOauth, disableOauth = _f === void 0 ? false : _f, _g = _a.disableServiceAccount, disableServiceAccount = _g === void 0 ? false : _g, _h = _a.serviceAccountKey, serviceAccountKey = _h === void 0 ? "config.auth.service_account_key" : _h, _j = _a.requireSubject, requireSubject = _j === void 0 ? false : _j, _k = _a.subjectKey, subjectKey = _k === void 0 ? "config.auth.subject" : _k, _l = _a.oauthSecretsRequired, oauthSecretsRequired = _l === void 0 ? true : _l;
    return removeNulls([
        {
            displayName: "Authorization Type",
            id: type,
            type: (0, types_1.selectionType)(removeNulls([!disableOauth && "OAuth", !disableServiceAccount && "Service Account"]), 1),
            required: true,
            defaultValue: removeNulls([!disableOauth && "OAuth", !disableServiceAccount && "Service Account"])[0],
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Jitsu provides two types for authorizing access to Google Services:", (0, jsx_runtime_1.jsxs)("ul", { children: [(0, jsx_runtime_1.jsxs)("li", { children: [(0, jsx_runtime_1.jsx)("b", { children: "OAuth" }), " \u2014 you'll need to provide Client Secret / Client Id (you can obtain in in Google Cloud Console) and get a refresh token. Jitsu developed a small", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://github.com/jitsucom/oauthcli" }, { children: "CLI utility to obtain those tokens" }))] }), (0, jsx_runtime_1.jsxs)("li", { children: [(0, jsx_runtime_1.jsx)("b", { children: "Service Account" }), " \u2014 you'll a)", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://cloud.google.com/iam/docs/creating-managing-service-account-keys" }, { children: "create a service account in Google Cloud Console" })), " ", "b) share google resource (such as ocument or analytics property) with this account (account email look like ", (0, jsx_runtime_1.jsx)("code", { children: "[username]@jitsu-customers.iam.gserviceaccount.com" }), ") c) put Service Account Key JSON (available in Google Cloud Console) in the field below"] })] })] })),
        },
        !disableOauth && {
            displayName: "OAuth Client ID",
            id: clientId,
            type: types_1.oauthSecretType,
            omitFieldRule: function (config) {
                //hack to make it work for singer based sources (which prefixes all fields with config. later on)
                var typeResolved = resolve(config, type) || resolve(config, "config.config." + type);
                return typeResolved !== "OAuth";
            },
            required: oauthSecretsRequired,
            documentation: oauthParamDocumentation,
        },
        !disableOauth && {
            displayName: "OAuth Client Secret",
            id: clientSecret,
            type: types_1.oauthSecretType,
            omitFieldRule: function (config) {
                //hack to make it work for singer based sources (which prefixes all fields with config. later on)
                var typeResolved = resolve(config, type) || resolve(config, "config.config." + type);
                return typeResolved !== "OAuth";
            },
            required: oauthSecretsRequired,
            documentation: oauthParamDocumentation,
        },
        !disableOauth && {
            displayName: "Refresh Token",
            id: refreshToken,
            type: types_1.stringType,
            omitFieldRule: function (config) {
                //hack to make it work for singer based sources (which prefixes all fields with config. later on)
                var typeResolved = resolve(config, type) || resolve(config, "config.config." + type);
                return typeResolved !== "OAuth";
            },
            required: true,
            documentation: oauthParamDocumentation,
        },
        !disableServiceAccount && {
            displayName: "Auth (Service account key JSON)",
            id: serviceAccountKey,
            type: types_1.jsonType,
            omitFieldRule: function (config) {
                //hack to make it work for singer based sources (which prefixes all fields with config. later on)
                var typeResolved = resolve(config, type) || resolve(config, "config.config." + type);
                return typeResolved !== "Service Account";
            },
            required: true,
            documentation: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://cloud.google.com/iam/docs/creating-managing-service-account-keys" }, { children: "Use Google Cloud Console to create Service Account get Service Key JSON" })) })),
        },
        !disableServiceAccount &&
            requireSubject && {
            displayName: "Subject",
            id: subjectKey,
            type: types_1.stringType,
            omitFieldRule: function (config) {
                //hack to make it work for singer based sources (which prefixes all fields with config. later on)
                var typeResolved = resolve(config, type) || resolve(config, "config.config." + type);
                return typeResolved !== "Service Account";
            },
            required: true,
            documentation: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "A Google Ads user with permissions on the Google Ads account you want to access. Google Ads does not support using service accounts without impersonation." })),
        },
    ]);
};
exports.googleAuthConfigParameters = googleAuthConfigParameters;
